import Typewriter from "typewriter-effect";

export default function TypeWriterComponent(props) {
	const builtComponent = (
		<Typewriter
			options={{
				strings: ["I am 18 years old.", "I live in Vancouver.", "I am a freshman at UBC.", "I like tech, business, and humanities.", "Hire me plz"],
				autoStart: true,
				loop: true,
				delay: props.delay || 150,
				pauseFor: props.pauseFor || 700,
			}}
		/>
	);
	return builtComponent;
}
